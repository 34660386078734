/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useDisclosure } from '@chakra-ui/hooks'
import {
	Avatar,
	AvatarGroup,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	useToast,
	Box,
	Button,
	Divider,
	HStack,
	Icon,
	IconButton,
	Slider,
	SliderFilledTrack,
	SliderTrack,
	Tag,
	TagLabel,
	Text,
	VStack,
	Center,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	ModalFooter,
	ModalHeader,
	List,
	ListItem,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
} from '@chakra-ui/react'
import { Collapse } from '@chakra-ui/transition'
import { format } from 'date-fns'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { FiCopy } from 'react-icons/fi'
import { BsFilePlus } from 'react-icons/bs'
import { FaPaperPlane } from 'react-icons/fa'
import { BiDownload, BiMailSend } from 'react-icons/bi'
import { RiDownloadCloud2Line } from 'react-icons/ri'
import { AiOutlineSetting, AiOutlineLineChart } from 'react-icons/ai'
import { RiMapPinUserLine } from 'react-icons/ri'
import {
	MdArrowDropDown,
	MdArrowDropUp,
	MdDateRange,
	MdMoreVert,
	MdEdit,
	MdHistory,
	MdDone,
	MdPeople,
	MdRefresh,
	MdTimelapse,
	MdAccessTime,
	MdNotifications,
	MdEmail,
	MdCheckCircle,
} from 'react-icons/md'
import { TiDocumentText } from 'react-icons/ti'
import { BiArrowBack } from 'react-icons/bi'
import { VscReferences } from 'react-icons/vsc'
import Card from '../../components/card'
import CustomMenu from '../../components/menu'
import { useSharedContext } from '../../context/shared.context'

import {
	useLoadActivityDetails,
	useLoadActivityParticipant,
	useExternalGuestsAdd,
	useAlterFileCover,
	useAddTimelineUpdate,
	useExportTimeLineData,
	useLoadChildOrderActivity,
	useActivityBulkSummary,
	useEmailBulkSummary,
	useLoadAccessSelectActivity,
	useCheckAccountTitleDup,
	useLoadResourcesBuildingList,
	useLoadPreviousStatusList,
	useSetPriority,
	useDeclineHistory,
} from '../../hooks/activity.hooks'
import { useLoadSubmittedFormList } from '../../hooks/form.hooks'
import { sharedConst } from '../../utils/action.constant'
import { WIDGET_WORKSPACE_MODE } from '../../utils/widget-constants'
import ReferenceFileContainer from './reference-files'
import TeamCollaboration from './team-collaboration'
import DataManagement from './data-management'
import StatusLifeCycle from './status-lifecycle'
import ParticipantView from './team-collaboration/participant-view'
import EmailView from './team-collaboration/email-communications'
import { WORKSPACE_MODE } from './workspace-panel.const'
import { SmartFormContextProvider } from '../../context/smart-form.context'
import SmartFormPanel from '../form-panel/smart-form-panel'
import { useAuthContext } from '../../context/auth.context'
import FormEditPanel from '../form-edit-panel'
import AddExtParticipant from '../meetings/add-meetings/events-form/add-participant'
import RenameModal from '../../components/CreateRenameModal'
import Mapcomponent from './Map-component'
import {
	formatDueDateForTimelineCurrDate,
	formatNoteUpdateDate,
	getLeaveTimeDate,
	getDeclineTimeDate,
} from '../../utils/date.utils'
import { downloadAwsFile } from '../../utils/aws.util'
import ExportTimelineDialog from '../../components/export-timeline-dialog'
import ExportFormsDialog from '../../components/export-forms-dialog'
import ExportChildOrders from '../../components/export-child-orders'
import OverviewPanel from './overview-panel'
import ActivityClone from '../../components/activity-list/activity-clone'
import { toSnakeCase, rag_status } from '../../utils/common.util'
import SendEmail from '../../components/send-email-model'
import { GridLoader } from 'react-spinners'
import Select from 'react-select'
import SelectParentAcitivity from '../../components/select-parent-activity'
import {
	useInviteParticipant,
	useAlterActivityStatus,
} from './../../hooks/activity.hooks'
import { useLoadManagerDetails } from './../../hooks/dashboard.hooks'
// import {
// 	eventLabelActionTrack,
// 	sectionDetailedTrack,
// 	GA_CATEGORY_WORKSPACE_PANEL,
// 	dataLayerTagManager,
// } from './../repository/repo.utils'
import { useLanguageContext } from '../../context/locale.context'
import { QRCodeSVG } from 'qrcode.react'

import WhatNextView from './what-next-panel'
import ExportData from '../../pages/export-data/export-data.page'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

const customStyles = {
	control: (styles, { isDisabled }) => {
		return {
			...styles,
			backgroundColor: isDisabled ? '#c0c0c0' : 'white',
			color: '#fff',
			borderColor: '#e6e6e6',
			borderRadius: '6px',
			margin: '5px 0 10px',
			width: '160px',
		}
	},
	option: (styles, { isDisabled }) => {
		return {
			...styles,
			backgroundColor: '#fff',
			color: '#000000',
			textAlign: 'left',
			width: '100%',
		}
	},
	container: provided => ({ ...provided, display: 'inline-block' }),
	menuPortal: provided => ({ ...provided, zIndex: 9999 }),
	menu: provided => ({ ...provided, zIndex: 9999 }),
}
const customPillStyles = {
	control: (styles, { isDisabled }) => {
		return {
			...styles,
			backgroundColor: isDisabled ? '#8FBC8F' : 'white',
			boxShadow: 'inset 0 0 0px 1px #38a169',
			borderColor: '#e6e6e6',
			borderRadius: '16px',
			fontSize: '12px',
			fontWeight: '400',
			margin: '0',
		}
	},
	option: (styles, { isDisabled }) => {
		return {
			...styles,
			backgroundColor: '#fff',
			color: '#38a169',
			textAlign: 'left',
			'&:hover': {
				backgroundColor: '#eae8ed',
			},
		}
	},
	singleValue: (styles, { isDisabled }) => ({
		...styles,
		position: 'static',
		transform: 'none',
		maxWidth: 'none',
		color: isDisabled ? '#fff' : '#38a169',
		paddingTop: '3px',
	}),

	menuPortal: provided => ({ ...provided, zIndex: 9999 }),
	menu: provided => ({
		...provided,
		zIndex: 9999,
		width: 'max-content',
		minWidth: '100%',
	}),
}

const WorkspacePanel = ({
	isDashboard,
	onWidgetViewChange,
	is_card_view = false,
	setToggleButton,
	setIsClickArrow,
}) => {
	const toast = useToast({
		duration: 3000,
	})
	const {
		state: {
			activity,
			activityTypeDetails,
			isReportingManagerOwner,
			isReportingManagerLead,
			openTeamCollab,
			activityParticipantId,
			isShowLoader,
			showWhatsNext,
			asset_notification_muted,
			isRefreshTimeline,
			random,
		},
		dispatch,
	} = useSharedContext()

	const {
		state: { authData },
	} = useAuthContext()
	const {
		state: { locale },
	} = useLanguageContext()

	const { asset_id, asset_flag_super_admin, organization_ai_bot_enabled } =
		authData

	const { activity_creator_asset_id, activity_lead_asset_id } = !!activity
		? activity
		: {}

	const cancelRef = React.useRef()

	const auth_asset_id = asset_id
	const what_next_access =  activity_lead_asset_id === auth_asset_id ? true : false

	const { isOpen, onToggle } = useDisclosure()
	const {
		isOpen: isOpenRenameActivity,
		onOpen: onOpenRenameActivity,
		onClose: onCloseRename,
	} = useDisclosure()
	const {
		isOpen: isOpenExportData,
		onOpen: onOpenExportData,
		onClose: onCloseExportData,
	} = useDisclosure()
	const { isOpen: isParticipantView, onOpen, onClose } = useDisclosure()
	const {
		isOpen: isEmailView,
		onOpen: onOpenEmial,
		onClose: OnCloseEmailView,
	} = useDisclosure()
	const {
		isOpen: isCloneActivity,
		onOpen: openCloneActivity,
		onClose: closeCloneActivity,
	} = useDisclosure()
	const {
		isOpen: isFormEditView,
		onOpen: onOpenFormEditView,
		onClose: onCloseFormEdit,
	} = useDisclosure()
	const {
		isOpen: isExtParticipantView,
		onOpen: onOpenExtParticipantView,
		onClose: onCloseExtParticipantView,
	} = useDisclosure()
	const {
		isOpen: isExportTimeline,
		onOpen: onOpenExportTimeline,
		onClose: onCloseExportTimeline,
	} = useDisclosure()
	const {
		isOpen: isDownloadChildOrders,
		onOpen: onDownloadChildOrders,
		onClose: onCloseDownloadChildOrders,
	} = useDisclosure()
	const {
		isOpen: isExportForms,
		onOpen: onOpenExportForms,
		onClose: onCloseExportForms,
	} = useDisclosure()
	const {
		isOpen: isSendEmail,
		onOpen: onOpenSendEmail,
		onClose: onCloseSendEmail,
	} = useDisclosure()
	const {
		isOpen: isSelectParentActivity,
		onOpen: onSelectParentActivity,
		onClose: onCloseSelectParentActivity,
	} = useDisclosure()
	const {
		isOpen: isDeclineOpen,
		onOpen: onOpenDecline,
		onClose: onCloseDecline,
	} = useDisclosure()
	const {
		isOpen: isOpenNoAccess,
		onOpen: onOpenNoAccess,
		onClose: onCloseNoAccess,
	} = useDisclosure()

	const [disableEditLead, setDisableEditLead] = useState(true)
	const [disableRollbackStatus, setDisableRollbackStatus] = useState(true)
	const pdf_ref = useRef()

	const { mutate: mutateSubmittedForm, isLoading: loadingSubmittedForm } =
		useLoadSubmittedFormList()
	const [isTimelineLoading, setLoadingTimeline] = useState(false)
	const [isFormsExpLoading, setLoadingFormsExp] = useState(false)
	const [submittedForms, setSubmittedForms] = useState([])
	const [selectedForms, setSelectedForms] = useState([])
	const [isSysMessage, setSysMesssage] = useState(false)
	const [participants, setparticipants] = useState([])
	const [openFormView, setopenFormView] = useState(null)
	const [refillView, setRefillView] = useState(false)
	const [isBulkEdit, setisBulkEdit] = useState(false)
	const { mutate } = useLoadActivityParticipant()
	const { mutate: mutateLoadActivity } = useLoadActivityDetails()
	const { mutate: mutateActivityBulkSummary } = useActivityBulkSummary()
	const [selectedUsers, setSelectedUsers] = useState([])
	const [externalUsers, setExternalUsers] = useState([])
	const [count, setCount] = useState(1)
	const [loadSuperAdmins, setloadSuperAdmins] = useState([])
	const [possibleLead, setPossibleLead] = useState()
	const [currentLead, setCurrentLead] = useState()
	const [previousStatus, setPreviousStatus] = useState()
	const [currentStatus, setCurrentStatus] = useState()
	const [mapOpenStatus, setMapOpenStatus] = useState(false)
	const [inviteDelegate, setInviteDelegate] = useState(false)
	const [declineData, setDeclineData] = useState([])
	const [showWhatsNextModal, setShowWhatNextModal] = useState(false)
	const [disableReferenceFiles, setDisableReferenceFiles] = useState(false)

	const { mutate: externalGuestsAddMutate } = useExternalGuestsAdd()
	const { mutate: mutateFileEditSave, isLoading: isActivityUpdating } =
		useAlterFileCover()
	const { mutate: mutateaddTimeLineUpdate } = useAddTimelineUpdate()
	const { mutate: exportTimelineMutate } = useExportTimeLineData()
	const { mutate: mutateEmailBulkSummary } = useEmailBulkSummary()
	const { mutate: loadAccessSelectActivity } = useLoadAccessSelectActivity()
	const { mutate: checkAccountTitleDup } = useCheckAccountTitleDup()
	const { mutate: mutateLoadManagerDetails } = useLoadManagerDetails()
	const { mutate: mutatePreviousStateList } = useLoadPreviousStatusList()
	const { mutate: mutateAlterActivityStatus } = useAlterActivityStatus()
	const { mutate: mutateLoadManagerResourcesList } =
		useLoadResourcesBuildingList()
	const { mutate: inviteMutate } = useInviteParticipant()
	const { mutate: setPriority } = useSetPriority()
	const { mutate: declineMutate } = useDeclineHistory()

	const { activity_id } = activity || {}

	const loadParticipants = useCallback(params => {
		mutate(
			{ ...params },
			{
				onSuccess: async data => {
					setExternalUsers(
						data ? data.list.filter(u => u.asset_type_category_id === 45) : []
					)
					setparticipants(data ? data.list : [])
				},
			}
		)
	})
	const triggerToast = ({ icon = MdNotifications, title, description }) => {
		toast({
			render: () => (
				<Box
					borderRadius='md'
					p={1}
					border='1'
					bg={localStorage.getItem('color')}
				>
					<HStack>
						<IconButton
							my={2}
							icon={<Icon as={icon} h={6} w={6} />}
							color={localStorage.getItem('color')}
							bg='white'
							_hover={{
								color: 'white',
								bg: localStorage.getItem('color'),
							}}
						/>
						<VStack w='300px' alignItems='flex-start'>
							<Text fontWeight='500' fontSize='md'>
								{title || ''}
							</Text>
							{description ? (
								<Text fontWeight='500'>{description} </Text>
							) : null}
						</VStack>
					</HStack>
				</Box>
			),
			status: 'error',
			duration: 2000,
			position: 'top-right',
			isClosable: true,
			variant: 'left-accent',
		})
	}

	const onEmailBulkSummary = useCallback(params => {
		const { activity_id, activity_type_id } = params

		if (externalUsers.length > 0) {
			mutateEmailBulkSummary(
				{
					parent_activity_id: activity_id,
					activity_id: activity_id,
					activity_type_id: activity_type_id,
				},
				{
					onSuccess: async data => {
						// console.log('Success data', data)
						triggerToast({
							title: `Summary has been shared over email!`,
							icon: MdCheckCircle,
						})
						const bulktimelineParams = buildTimelineBulkSummaryParams()
						mutateaddTimeLineUpdate(
							{ ...bulktimelineParams },
							{
								onSuccess: async () => {
									dispatch({
										type: sharedConst.REFRESH_TIMELINE,
									})
								},
							}
						)
					},
					onError: async error => {
						console.log('Error on email', error)
					},
				}
			)
		} else {
			alert('Please add external participants to send email summary')
		}
	})

	const onExportBulkSummary = useCallback(params => {
		const { activity_id, activity_type_id } = params
		mutateActivityBulkSummary(
			{
				parent_activity_id: activity_id,
				activity_type_id,
			},
			{
				onSuccess: async data => {
					triggerToast({
						title: `Bulk summary has been exported!`,
						icon: MdCheckCircle,
					})
					dispatch({
						type: sharedConst.REFRESH_TIMELINE,
					})
				},
				onError: async err => {
					console.log('Error ', err)
				},
			}
		)
	})

	const loadSubmittedForm = useCallback(params => {
		const { activity_id, activity_type_id, activity_type_category_id } = params
		console.log('loading submitted forms!')
		mutateSubmittedForm(
			{
				flag: 1,
				form_id: 0, // form id should be 0 to load all forms
				activity_id,
				activity_type_id,
				activity_type_category_id,
			},
			{
				onSuccess: async data => {
					if (Array.isArray(data) && data.length > 0) {
						setSubmittedForms(data.reverse())
					}
				},
			}
		)
	})

	const [workspaceMode, setworkspaceMode] = useState(
		WORKSPACE_MODE.TEAM_COLLABORATION
	)

	const rollbackStatus = () => {
		if (disableRollbackStatus) {
			mutatePreviousStateList(
				{
					activity_id,
					activity_status_id,
				},
				{
					onSuccess: data => {
						let prevStatus = []
						data.map(status => {
							prevStatus.push({
								label: status.from_activity_status_name,
								value: status.from_activity_status_id,
								data: status,
							})
						})
						setPreviousStatus(prevStatus)
						setDisableRollbackStatus(false)
					},
				}
			)
		} else {
			if (currentStatus.value !== activity_status_id) {
				mutateAlterActivityStatus(
					{
						activity_id,
						activity_status_id: currentStatus.data.from_activity_status_id,
						activity_status_type_id:
							currentStatus.data.from_activity_status_type_id,
					},
					{
						onSuccess: data => {
							setPreviousStatus([])
							loadDetails(activity)
							setTimeout(() => {
								dispatch({
									type: sharedConst.REFRESH_LISTING,
								})
							}, 1000)
						},
					}
				)
			}
			setDisableRollbackStatus(true)
		}
	}

	const changeLeadByManager = () => {
		if (disableEditLead) {
			mutateLoadManagerResourcesList(
				{
					lead_asset_type_id: activity.activity_lead_asset_type_id,
					manager_asset_id: authData.asset_id,
					lead_asset_id: activity.activity_lead_asset_id,
				},
				{
					onSuccess: data => {
						let lead = [
							{
								value: activity_lead_operating_asset_id,
								label: activity_lead_operating_asset_first_name,
							},
						]
						data.map(asset => {
							lead.push({
								value: asset.asset_id,
								label: asset.operating_asset_first_name,
								data: asset,
							})
						})
						setPossibleLead(lead)
						setDisableEditLead(false)
					},
				}
			)
		} else {
			if (currentLead.value !== activity_lead_operating_asset_id) {
				inviteMutate(
					{
						activity_id,
						activity_type_category_id,
						activity_type_id,
						add_as_lead: 1,
						from_user_interface: 1,
						contact: {
							asset_type_id: currentLead.data.asset_type_id,
							asset_id: currentLead.data.asset_id,
							asset_first_name: currentLead.data.asset_first_name,
							operating_asset_first_name:
								currentLead.data.operating_asset_first_name,
							workforce_name: currentLead.data.workforce_name,
						},
					},
					{
						onSuccess: data => {
							loadDetails(activity)
							setTimeout(() => {
								dispatch({
									type: sharedConst.REFRESH_LISTING,
								})
							}, 1000)
						},
					}
				)
			}
			setDisableEditLead(true)
		}
	}

	const loadDetails = useCallback(activity => {
		mutateLoadActivity(
			{
				activity_id: activity.activity_id,
				activity_type_id: activity.activity_type_id,
			},
			{
				onSuccess: async details => {
					const isLead = asset_id === details.activity_lead_asset_id
					const isOwner = asset_flag_super_admin
					setDisableReferenceFiles(
						Number(details.activity_type_flag_disable_reference_files) === 1
							? true
							: false
					)
					dispatch({
						type: sharedConst.ACTVITY_SELECTED,
						payload: {
							...details,
							isLead,
							isOwner,
							asset_flag_is_owner: !!activity.asset_flag_is_owner,
						},
					})
				},
				onError: async err => {
					console.log('Err occurred', err)
				},
			}
		)
	})

	const handleUserSelect = e => {
		let uIndex = selectedUsers.findIndex(u => u === e)
		let users = uIndex === -1 ? [...selectedUsers, e] : [...selectedUsers]
		setSelectedUsers(users)
	}

	const removeSelectedUser = e => {
		let uIndex = selectedUsers.findIndex(u => u.email === e)
		if (uIndex > -1) {
			selectedUsers.splice(uIndex, 1)
		}
		setSelectedUsers(selectedUsers)
		setCount(count + 1)
	}

	const addGuests = () => {
		if (selectedUsers.length > 0) {
			addExternalGuests({
				emails: selectedUsers,
				workflow_activity_id: activity.activity_id,
				activity_type_id: activity.activity_type_id,
			})
		} else {
			alert('Enter email id')
		}
	}

	const addExternalGuests = useCallback(params => {
		onCloseExtParticipantView()
		externalGuestsAddMutate(
			{ ...params },
			{
				onSuccess: async data => {
					setSelectedUsers([])
					loadDetails(activity)
					dispatch({
						type: sharedConst.REFRESH_TIMELINE,
					})
				},
				onError: async data => {
					setSelectedUsers([])
					loadDetails(activity)
					dispatch({
						type: sharedConst.REFRESH_TIMELINE,
					})
				},
			}
		)
	})

	const onFormRefill = (status = true, isBEdit = false) => {
		setRefillView(true)
		setisBulkEdit(isBEdit)
		setworkspaceMode(WORKSPACE_MODE.FORM_SUBMISSION_VIEW)
	}

	const doRefresh = (i = 0) => {
		let timer = setTimeout(() => {
			// console.log('refresh ===> ', i, new Date().toLocaleString())
			dispatch({
				type: sharedConst.SHOW_LOADER,
			})
			dispatch({
				type: sharedConst.REFRESH_TIMELINE,
			})
			doRefresh(i + 1)
		}, 3000)
		if (i > 3) {
			clearTimeout(timer)
			//setworkspaceMode(WORKSPACE_MODE.TEAM_COLLABORATION);
			// loadDetails(activity);
			dispatch({
				type: sharedConst.REFRESH_LISTING,
			})
			dispatch({
				type: sharedConst.REFRESH_TIMELINE,
			})
			dispatch({
				type: sharedConst.HIDE_LOADER,
			})
		}
	}

	useEffect(() => {
		if (isDashboard) {
			setTimeout(() => {
				loadDetails(activity)
				dispatch({
					type: sharedConst.REFRESH_TIMELINE,
				})
				dispatch({
					type: sharedConst.NEW_FORM,
				})
			}, 1000)
		}
	}, [isDashboard])

	useEffect(() => {
		if (!!isRefreshTimeline || random) {
			loadParticipants({ activity_id })
		}
	}, [isRefreshTimeline, random])

	useEffect(() => {
		if (!!activity) {
			setTimeout(() => {
				loadParticipants({ activity_id })
				// setworkspaceMode(WORKSPACE_MODE.TEAM_COLLABORATION);
				loadSubmittedForm(activity)
				setDisableEditLead(true)
				setDisableRollbackStatus(true)
				setCurrentLead({
					value: activity_lead_operating_asset_id,
					label: activity_lead_operating_asset_first_name,
				})
				setCurrentStatus({
					value: activity.activity_status_id,
					label: activity.activity_status_name,
				})
				if (workspaceMode === WORKSPACE_MODE.TEAM_COLLABORATION) {
					setShowWhatNextModal(
						what_next_access &&
							activity?.activity_type_flag_enable_whatnext === 1
					)
				}
			}, 1000)
			loadDetails(activity)
		}
	}, [activity?.activity_id])

	useEffect(() => {
		if (
			workspaceMode === WORKSPACE_MODE.DATA_MANAGEMENT ||
			workspaceMode === WORKSPACE_MODE.TEAM_COLLABORATION
		) {
			setShowWhatNextModal(
				what_next_access && activity?.activity_type_flag_enable_whatnext === 1
			)
		}
	}, [workspaceMode])

	useEffect(() => {
		;(async () => {
			let a_id = localStorage.getItem('DASHBOARD_ACTIVITY_ID')
			if (!!a_id) {
				await loadDetails({ activity_id: a_id })
				await setworkspaceMode(WORKSPACE_MODE.TEAM_COLLABORATION)
				await localStorage.removeItem('DASHBOARD_ACTIVITY_ID')
			}
		})()
	}, [localStorage.getItem('DASHBOARD_ACTIVITY_ID')])

	useEffect(() => {
		if (!!openTeamCollab) {
			setTimeout(() => {
				setworkspaceMode(WORKSPACE_MODE.TEAM_COLLABORATION)
				dispatch({
					type: sharedConst.RESET_TO_TIMELINE,
					openTeamCollab: false,
				})
			}, 1000)
		}
	}, [openTeamCollab])

	const buildTitleUpdateParams = updatedTitle => {
		const {
			activity_title,
			activity_datetime_end_deferred,
			activity_id,
			activity_type_id,
		} = activity
		const coverPayload = {
			title: {
				old: activity_title,
				new: updatedTitle,
			},
			description: { old: '', new: '' },
			duedate: {
				old: activity_datetime_end_deferred,
				new: activity_datetime_end_deferred,
			},
		}
		return {
			activity_id: activity_id,
			activity_cover_data: JSON.stringify(coverPayload),
			activity_type_id: activity_type_id,
		}
	}

	const buildTimelineEntryParams = updatedTitle => {
		const {
			activity_title,
			activity_id,
			activity_type_category_id,
			parent_activity_id,
		} = activity
		const { operating_asset_first_name } = authData
		let activityTimelineJson = {
			content: `Activity title changed from "${activity_title}" to "${updatedTitle}" by ${operating_asset_first_name} @
                ${formatDueDateForTimelineCurrDate()}`,
			subject: 'Note - ' + formatNoteUpdateDate(),
			mail_body: `Activity title changed from "${activity_title}" to "${updatedTitle}" by ${operating_asset_first_name} @
                ${formatDueDateForTimelineCurrDate()}`,
			attachments: [],
			activity_reference: [
				{
					activity_title: '',
					activity_id: '',
				},
			],
			asset_reference: [],
			form_approval_field_reference: [],
		}
		return {
			activity_id: activity_id,
			activity_type_category_id: activity_type_category_id,
			activity_type_id: activity_type_id,
			activity_parent_id: parent_activity_id,
			activity_timeline_collection: JSON.stringify(activityTimelineJson),
			activity_stream_type_id: 711,
			timeline_stream_type_id: 711,
			data_entity_inline: JSON.stringify(activityTimelineJson),
			operating_asset_first_name: operating_asset_first_name,
		}
	}

	const buildTimelineBulkSummaryParams = () => {
		const {
			activity_title,
			activity_id,
			activity_type_category_id,
			parent_activity_id,
		} = activity
		const { operating_asset_first_name } = authData
		let activityTimelineJson = {
			content: `Bulk summary email sent`,
			subject: 'Note - ' + formatNoteUpdateDate(),
			mail_body: `Bulk summary email sent`,
			attachments: [],
			activity_reference: [
				{
					activity_title: '',
					activity_id: '',
				},
			],
			asset_reference: [],
			form_approval_field_reference: [],
		}
		return {
			activity_id: activity_id,
			activity_type_category_id: activity_type_category_id,
			activity_type_id: activity_type_id,
			activity_parent_id: parent_activity_id,
			activity_timeline_collection: JSON.stringify(activityTimelineJson),
			activity_stream_type_id: 509,
			timeline_stream_type_id: 509,
			// data_entity_inline: JSON.stringify(activityTimelineJson),
			operating_asset_first_name: operating_asset_first_name,
			bulk_summary: true,
		}
	}

	const onRenameActivity = activityName => {
		if (activity.activity_type_category_id === 53) {
			const {
				activity_title,
				activity_id,
				activity_type_id,
				workflow_activity_id,
			} = activity
			if (activity_title === activityName) return
			checkAccountTitleDup(
				{
					activity_id,
					activity_type_id,
					workflow_activity_id,
					activity_title: activityName,
				},
				{
					onSuccess: async data => {
						if (data.status === 200) {
							toRenameActivity(activityName)
						} else {
							alert(data.response[0].message)
							//alert('Account title already exists');
							onCloseRename()
						}
					},
					onError: async err => {
						console.log({ err })
					},
				}
			)
		} else {
			toRenameActivity(activityName)
		}
	}

	const toRenameActivity = activityName => {
		const { activity_title } = activity
		if (activity_title !== activityName) {
			let titleParams = buildTitleUpdateParams(activityName)
			let timelineParams = buildTimelineEntryParams(activityName)
			mutateFileEditSave(
				{ activity, ...titleParams },
				{
					onSuccess: async () => {
						dispatch({
							type: sharedConst.REFRESH_ACTVITY_LISTING,
							refreshListing: true,
						})
						mutateaddTimeLineUpdate(
							{ ...timelineParams },
							{
								onSuccess: async () => {
									loadDetails(activity)
									dispatch({
										type: sharedConst.REFRESH_TIMELINE,
									})
									onCloseRename()
								},
							}
						)
					},
				}
			)
		}
	}

	const buildTimelineExportPayload = () => {
		const data = {
			organization_id: authData.organization_id,
			account_id: authData.account_id,
			workforce_id: authData.workforce_id,
			asset_id: authData.asset_id,
			asset_token_auth: authData.asset_token_auth,
			activity_id: activity.activity_id,
			activity_type_category_id: activity.activity_type_category_id,
			activity_stream_type_id: 705,
			activity_type_id: activity.activity_type_id,
			asset_first_name: authData.asset_first_name,
			asset_phone_number: authData.asset_phone_number,
			operating_asset_first_name: authData.operating_asset_first_name,
			show_headers: 0,
			isSysMessageEnable: isSysMessage,
			us: true,
			isTimelineViewExport: selectedForms.length === 0,
			f_list:
				selectedForms.length > 0
					? selectedForms.map(form => {
							return {
								form_id: form.value,
								form_name: form.label,
								form_tx_id: form.data.form_transaction_id,
								s_id: form.data.form_sequence_id,
							}
					  })
					: [],
		}
		return data
		// return btoa(JSON.stringify(data))
	}

	const exportFormsData = () => {
		if (selectedForms.length > 0) {
			setLoadingFormsExp(true)
			onOpenExportData()
			setLoadingTimeline(false)
			setLoadingFormsExp(false)
			onCloseExportForms()
		} else {
			alert('Select form')
		}
	}

	const exportTimelineData = () => {
		setLoadingTimeline(true)
		setLoadingFormsExp(false)
		setSelectedForms([])
		onOpenExportData()
		setLoadingTimeline(false)
		onCloseExportTimeline()
	}

	const onSetPriority = priority => {
		setPriority(
			{
				activity_id: activity.activity_id,
				activity_enable_priority: priority,
			},
			{
				onSuccess: async res => {
					// sectionDetailedTrack({
					// 	category: GA_CATEGORY_WORKSPACE_PANEL,
					// 	action: 'Load Details Of Activity',
					// })
					loadDetails(activity)
					dispatch({
						type: sharedConst.REFRESH_TIMELINE,
					})
				},
			}
		)
	}

	const canClone = () => {
		// only creator can do this
		const { activity_type_category_id, activity_creator_asset_id } = activity
		if (
			activity_creator_asset_id === asset_id &&
			[48, 31].includes(activity_type_category_id)
		) {
			return true
		}
		return false
	}

	useEffect(() => {
		if (!!activity) {
			loadAccessSelectActivity(
				{
					activity_type_id: activity?.activity_type_id,
					flag: 0,
				},
				{
					onSuccess: async data => {
						setloadSuperAdmins(data)
					},
					onError: async err => {
						console.log('err', err)
					},
				}
			)
			const { activity_id, activity_type_category_id, activity_type_id } =
				activity
			declineMutate(
				{
					activity_id,
					activity_type_id,
					activity_type_category_id,
					workflow_activity_id: activity_id,
					start_from: 0,
					limit_value: 50,
					form_id: 0,
				},
				{
					onSuccess: async data => {
						setDeclineData(data)
					},
					onError: async err => {
						setDeclineData([])
					},
				}
			)
		}
	}, [activity])

	const activityEnable = (
		owner_access = null,
		form_flag_enable_data_secrecy = 0
	) => {
		const isLead = !!activity.activity_lead_asset_id
			? authData.asset_id === activity.activity_lead_asset_id
			: false
		// const superAdmin = asset_flag_super_admin == 1 ? true : false;
		let participant = participants.find(
			item => item.asset_id === authData.asset_id
		)
		const isOwner =
			!!isReportingManagerOwner ||
			!!isReportingManagerLead ||
			(owner_access === null
				? !!participant
					? participant.asset_flag_is_owner === 1
						? true
						: false
					: false
				: owner_access)
		/* let isSuperAdminCheck;
	let isUserHaveAccess;
	if (!!loadSuperAdmins.length) {
	  isSuperAdminCheck = loadSuperAdmins.find(
		item => item.asset_type_id === authData.asset_type_id
	  );
	  isUserHaveAccess = loadSuperAdmins.find(
		item => item.asset_id === authData.asset_id
	  );
	}*/
		if (
			isOwner ||
			(!form_flag_enable_data_secrecy && isLead) ||
			isSuperAdmin()
		) {
			return true
		} else {
			return false
		}
	}

	const isSuperAdmin = () => {
		const superAdmin = asset_flag_super_admin === 1 ? true : false
		let isSuperAdminCheck
		let isUserHaveAccess
		if (!!loadSuperAdmins.length) {
			isSuperAdminCheck = loadSuperAdmins.find(
				item => item.asset_type_id === authData.asset_type_id
			)
			isUserHaveAccess = loadSuperAdmins.find(
				item => item.asset_id === authData.asset_id
			)
		}
		if (superAdmin || !!isSuperAdminCheck || !!isUserHaveAccess) {
			return true
		} else {
			return false
		}
	}

	const mutateInviteDelegate = user => {
		inviteMutate(
			{
				activity_id,
				activity_type_category_id,
				activity_type_id,
				add_as_lead: 1,
				from_user_interface: 1,
				contact: {
					asset_type_id: user.asset_type_id,
					asset_id: user.asset_id,
					asset_first_name: user.asset_first_name,
					operating_asset_first_name: user.operating_asset_first_name,
					workforce_name: user.workforce_name,
				},
			},
			{
				onSuccess: data => {
					loadDetails(activity)
					onClose()
					loadParticipants({ activity_id })
					dispatch({
						type: sharedConst.HANDLE_WHATSNEXT,
						payload: false,
					})
					setShowWhatNextModal(false)
					setTimeout(() => {
						dispatch({
							type: sharedConst.REFRESH_LISTING,
						})
						setInviteDelegate(false)
					}, 1000)
				},
			}
		)
	}

	if (!activity) {
		return (
			<VStack>
				<OverviewPanel setToggleButton={setToggleButton} />
			</VStack>
		)
	}

	const {
		activity_lead_operating_asset_first_name,
		operating_asset_first_name,
		activity_type_category_name,
		activity_type_category_id,
		activity_type_name,
		activity_status_name,
		activity_cuid_1,
		activity_status_id,
		activity_cuid_2,
		activity_cuid_3,
		activity_datetime_start_expected,
		activity_datetime_end_deferred,
		activity_datetime_end_status,
		participant_list,
		activity_rag_status_name,
		activity_workflow_completion_percentage: completion,
		activity_type_id,
		activity_lead_operating_asset_id,
		activity_type_flag_enable_whatnext,
	} = activity
	const timeColor = datetime => {
		let isDelayed =
			new Date(datetime.split(' ').join('T')).setHours(0, 0, 0, 0) <
			new Date().setHours(0, 0, 0, 0)
		if (!!isDelayed) {
			return 'red'
		} else {
			return ''
		}
	}

	const showDeclineHistory = flag => {
		onOpenDecline()
	}

	const handleDownload = async () => {
		const divToPrint = pdf_ref?.current
		setTimeout(() => {
			html2canvas(divToPrint).then(canvas => {
				const imgData = canvas.toDataURL('image/png')
				const imgWidth = 190
				const pageHeight = 290
				const imgHeight = (canvas.height * imgWidth) / canvas.width
				let heightLeft = imgHeight
				const doc = new jsPDF('pt', 'mm')
				let position = 0
				doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25)
				heightLeft -= pageHeight
				while (heightLeft >= 0) {
					position = heightLeft - imgHeight
					doc.addPage()
					doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight + 25)
					heightLeft -= pageHeight
				}
				doc.save('download.pdf')
			})
		}, 1000)
		// const pdf = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
		// pdf.html(divToPrint, { html2canvas: { scale: 0.57 } }).then(() => {
		// 	pdf.save("download.pdf");
		// });
	}
	const isDifferentActivityCreator =
		activity.activity_creator_asset_id !== asset_id
	const isNotSuperAdmin = asset_flag_super_admin !== 1
	const isDisabled = isDifferentActivityCreator || isNotSuperAdmin
	return (
		<VStack className={`workspace-panel-container`}>
			<ExportTimelineDialog
				isOpen={isExportTimeline}
				exportTimeline={exportTimelineData}
				onClose={onCloseExportTimeline}
				isLoading={isTimelineLoading}
				isSysMessage={isSysMessage}
				setSysMesssage={setSysMesssage}
			/>
			<ExportFormsDialog
				isOpen={isExportForms}
				exportForms={exportFormsData}
				onClose={onCloseExportForms}
				isLoading={isFormsExpLoading}
				formsList={submittedForms}
				setSelectedForms={setSelectedForms}
				selectedForms={selectedForms}
			/>
			<SelectParentAcitivity
				isOpen={isSelectParentActivity}
				onClose={onCloseSelectParentActivity}
				activity={activity}
			/>
			<ExportChildOrders
				isOpen={isDownloadChildOrders}
				onClose={onCloseDownloadChildOrders}
				activity={activity}
			/>
			<SendEmail
				isOpen={isSendEmail}
				onClose={onCloseSendEmail}
				activity={activity}
			/>
			{isParticipantView ? (
				<ParticipantView
					activity={activity}
					activityTypeDetails={activityTypeDetails}
					isOpen={isParticipantView}
					onClose={() => {
						onClose()
						loadParticipants({ activity_id })
					}}
					addExternalUsers={() => {
						onClose()
						onOpenExtParticipantView()
					}}
					inviteDelegate={inviteDelegate}
					addDelegate={e => {
						mutateInviteDelegate(e)
					}}
				/>
			) : null}
			{isEmailView ? (
				<EmailView
					activity={activity}
					activityTypeDetails={activityTypeDetails}
					isOpen={isEmailView}
					onClose={() => {
						OnCloseEmailView()
						//loadParticipants({ activity_id })
					}}
					addExternalUsers={() => {
						onClose()
						onOpenExtParticipantView()
					}}
					inviteDelegate={inviteDelegate}
					addDelegate={e => {
						mutateInviteDelegate(e)
					}}
				/>
			) : null}
			{showWhatsNextModal && !!activity_type_flag_enable_whatnext ? (
				<WhatNextView
					activity={activity}
					isOpen={showWhatsNextModal}
					onClose={() => {
						dispatch({
							type: sharedConst.HANDLE_WHATSNEXT,
							payload: false,
						})
						setShowWhatNextModal(false)
						setInviteDelegate(false)
					}}
					onOpenParticipantView={() => {
						onOpen()
						setInviteDelegate(true)
					}}
					participants={participants}
					showDeclineHistory={showDeclineHistory}
					asset_notification_muted={asset_notification_muted}
					onViewLifeCycle={() => {
						// eventLabelActionTrack({
						// 	categoryId: 0,
						// 	action: 'Status Life Cycle',
						// 	label: 'Switch Status Life Cycle View',
						// })
						loadDetails(activity)
						setworkspaceMode(WORKSPACE_MODE.STATUS_LIFECYCLE)
						dispatch({
							type: sharedConst.HANDLE_WHATSNEXT,
							payload: false,
						})
						setShowWhatNextModal(false)
						setInviteDelegate(false)
					}}
				>
					<DataManagement
						participants={participants}
						isOverviewOpen={false}
						isFromWN={true}
						isFormEnable={activityEnable()}
						activity={activity}
						onFormSelection={(f, isEdit = false, owner_access = true) => {
							const { activity_id, activity_type_id } = activity
							setopenFormView({
								...f,
								disableEdit: false,
								activity_id,
								activity_type_id,
							})
							setisBulkEdit(false)
							setRefillView(false)
							if (!!isEdit && activityEnable(owner_access)) {
								onOpenFormEditView()
							} else if (!!isEdit && !activityEnable(owner_access)) {
								onOpenNoAccess()
							} else {
								if (activityEnable(owner_access)) {
									setworkspaceMode(WORKSPACE_MODE.FORM_SUBMISSION_VIEW)
								} else {
									onOpenNoAccess()
								}
							}
							dispatch({
								type: sharedConst.HANDLE_WHATSNEXT,
								payload: false,
							})
							setShowWhatNextModal(false)
						}}
						onDelegateFormClose={() => {
							setworkspaceMode(WORKSPACE_MODE.DATA_MANAGEMENT)
							loadDetails(activity)
							setTimeout(() => {
								dispatch({
									type: sharedConst.REFRESH_LISTING,
								})
							}, 1000)
							dispatch({
								type: sharedConst.HANDLE_WHATSNEXT,
								payload: false,
							})
							setShowWhatNextModal(false)
						}}
						isFromWhatNext={true}
					/>
				</WhatNextView>
			) : null}
			{isExtParticipantView && (
				<AddExtParticipant
					selectedUsers={selectedUsers}
					handleUserSelect={handleUserSelect}
					removeSelectedUser={removeSelectedUser}
					isOpen={isExtParticipantView}
					onClose={() => {
						onCloseExtParticipantView()
					}}
					onSubmit={addGuests}
				/>
			)}
			{isFormEditView && (
				<SmartFormContextProvider>
					<FormEditPanel
						isOpen={isFormEditView}
						onClose={onCloseFormEdit}
						activity={activity}
						formData={openFormView}
						refillForm={onFormRefill}
						isFormEnable={activityEnable()}
					/>
				</SmartFormContextProvider>
			)}

			<Card minH='40px' maxH='250px' className={`activity-container`}>
				<HStack
					alignItems='center'
					bg={
						!!activity?.activity_priority_enabled
							? localStorage.getItem('color')
							: 'white'
					}
					padding={!!activity?.activity_priority_enabled ? '2' : ''}
					className={`activity-${toSnakeCase(activity?.activity_title)}`}
				>
					{isDashboard ? (
						<IconButton
							bg='white'
							borderRadius='md'
							boxShadow='md'
							aria-label='Refresh'
							cursor='pointer'
							size='md'
							_focus={{
								bg: 'secondary',
							}}
							_hover={{
								bg: 'secondary',
							}}
							onClick={() => {
								// sectionDetailedTrack({
								// 	category: GA_CATEGORY_WORKSPACE_PANEL,
								// 	action: 'Go Back',
								// })
								if (is_card_view) {
									onWidgetViewChange(WIDGET_WORKSPACE_MODE.WIDGET_CARD_DATA)
								} else {
									onWidgetViewChange(WIDGET_WORKSPACE_MODE.WIDGET_DATA)
								}
								setIsClickArrow(true) //simha
							}}
							icon={<Icon as={BiArrowBack} w={6} h={6} />}
						/>
					) : null}
					<Text
						flex={1}
						fontSize='md'
						onClick={() => {
							// sectionDetailedTrack({
							// 	category: GA_CATEGORY_WORKSPACE_PANEL,
							// 	action: 'Expand Activity Status Data',
							// })
							onToggle()
						}}
						color='black'
						textTransform='capitalize'
						className={`activity-name-text-${toSnakeCase(
							activity?.activity_title
						)}`}
					>
						{activity?.activity_title}
					</Text>

					{activity?.activity_location_latitude &&
					activity?.activity_location_latitude !== 0 ? (
						<IconButton
							className={`activity-reload-button-${toSnakeCase(
								activity?.activity_title
							)} ${isOpen ? 'expand' : ''}`}
							bg='white'
							borderRadius='md'
							boxShadow='sm'
							aria-label='Refresh'
							size='sm'
							_focus={{
								bg: 'secondary',
							}}
							_hover={{
								bg: 'secondary',
							}}
							icon={<Icon as={RiMapPinUserLine} w={6} h={6} />}
							onClick={() => {
								setMapOpenStatus(true)
							}}
						/>
					) : (
						''
					)}
					{mapOpenStatus && (
						<Mapcomponent
							lat={activity?.activity_location_latitude || 0}
							lng={activity?.activity_location_longitude || 0}
							setMapOpenStatus={setMapOpenStatus}
						/>
					)}
					<Icon
						className={`activity-arrow-icon-${toSnakeCase(
							activity?.activity_title
						)}`}
						cursor='pointer'
						as={!isOpen ? MdArrowDropDown : MdArrowDropUp}
						w={7}
						h={7}
						onClick={() => {
							// sectionDetailedTrack({
							// 	category: GA_CATEGORY_WORKSPACE_PANEL,
							// 	action: 'Expand Activity Status Data',
							// })
							onToggle()
						}}
					/>
					<IconButton
						className={`activity-reload-button-${toSnakeCase(
							activity?.activity_title
						)} ${isOpen ? 'expand' : ''}`}
						bg='white'
						borderRadius='md'
						boxShadow='sm'
						aria-label='Refresh'
						size='sm'
						_focus={{
							bg: 'secondary',
						}}
						_hover={{
							bg: 'secondary',
						}}
						isDisabled={activityParticipantId === 152}
						icon={<Icon as={MdRefresh} w={6} h={6} />}
						onClick={() => {
							// sectionDetailedTrack({
							// 	category: GA_CATEGORY_WORKSPACE_PANEL,
							// 	action: 'Load Details Of Activity',
							// })
							loadDetails(activity)
							dispatch({
								type: sharedConst.REFRESH_TIMELINE,
							})
						}}
					/>

					<CustomMenu
						className={`activity-menu-${toSnakeCase(
							activity?.activity_title
						)} ${isOpen ? 'expand' : ''}`}
						icon={MdMoreVert}
						bg='white'
						borderRadius='md'
						boxShadow='sm'
						aria-label='More'
						size='sm'
						_focus={{
							bg: 'secondary',
						}}
						_hover={{
							bg: 'secondary',
						}}
						isDisabled={activityParticipantId === 152}
						optionList={[
							{
								label: locale['Clone'],
								isMenu: true,
								icon: <Icon as={BsFilePlus} w={5} h={5} color='brand.900' />,
								onClick: e => {
									// sectionDetailedTrack({
									// 	category: GA_CATEGORY_WORKSPACE_PANEL,
									// 	action: 'Clone Activity',
									// })
									// dataLayerTagManager('button_click', {
									// 	viewName: 'Workflow Navbar',
									// 	buttonName: 'Clone',
									// })
									openCloneActivity()
								},
								disabled: !canClone(),
							},
							{
								label: locale['Change title'],
								isMenu: true,
								icon: <Icon as={MdEdit} w={5} h={5} color='brand.900' />,
								disabled:
									activity.activity_creator_asset_id !== asset_id &&
									!isSuperAdmin(),
								onClick: e => {
									// sectionDetailedTrack({
									// 	category: GA_CATEGORY_WORKSPACE_PANEL,
									// 	action: 'Open Rename Activity Modal',
									// })
									// dataLayerTagManager('button_click', {
									// 	viewName: 'Workflow Navbar',
									// 	buttonName: 'Change title',
									// })
									onOpenRenameActivity()
								},
							},
							{
								label: locale['Set Parent Activity'],
								isMenu: true,
								icon: (
									<Icon as={AiOutlineSetting} w={5} h={5} color='brand.900' />
								),
								disabled:
									activity.activity_creator_asset_id !== asset_id ||
									activity.activity_type_category_id === 53 ||
									activity.activity_type_category_id === 54,
								onClick: e => {
									// sectionDetailedTrack({
									// 	category: GA_CATEGORY_WORKSPACE_PANEL,
									// 	action: 'Open Select Parent Activity Modal',
									// })
									// dataLayerTagManager('button_click', {
									// 	viewName: 'Workflow Navbar',
									// 	buttonName: 'Set Parent Activity',
									// })
									onSelectParentActivity()
								},
							},
							{
								label: locale['Download Child Orders'],
								isMenu: true,
								icon: <Icon as={BiDownload} w={5} h={5} color='brand.900' />,
								disabled:
									activity.activity_sub_type_id !== 1 &&
									(activity.activity_type_category_id === 31 ||
										activity.activity_type_category_id === 48),
								onClick: e => {
									// sectionDetailedTrack({
									// 	category: GA_CATEGORY_WORKSPACE_PANEL,
									// 	action: 'Open Download Child Orders Modal',
									// })
									// dataLayerTagManager('button_click', {
									// 	viewName: 'Workflow Navbar',
									// 	buttonName: 'Download Child Orders',
									// })
									// console.log('activity', activity.activity_sub_type_id)
									onDownloadChildOrders()
								},
							},
							{
								label: locale['Export bulk summary'],
								isMenu: true,
								icon: <Icon as={BiDownload} w={5} h={5} color='brand.900' />,
								disabled:
									activity.activity_sub_type_id !== 1 &&
									(activity.activity_type_category_id === 31 ||
										activity.activity_type_category_id === 48),
								onClick: e => {
									// sectionDetailedTrack({
									// 	category: GA_CATEGORY_WORKSPACE_PANEL,
									// 	action: 'Open Export Bulk Summary Modal',
									// })
									// dataLayerTagManager('button_click', {
									// 	viewName: 'Workflow Navbar',
									// 	buttonName: 'Export bulk summary',
									// })
									onExportBulkSummary(activity)
								},
							},
							{
								label: locale['Email Summary'],
								isMenu: true,
								icon: <Icon as={BiMailSend} w={5} h={5} color='brand.900' />,
								disabled:
									activity.activity_sub_type_id !== 1 &&
									(activity.activity_type_category_id === 31 ||
										activity.activity_type_category_id === 48),
								onClick: e => {
									// sectionDetailedTrack({
									// 	category: GA_CATEGORY_WORKSPACE_PANEL,
									// 	action: 'Send Email Bulk Summary',
									// })
									// dataLayerTagManager('button_click', {
									// 	viewName: 'Workflow Navbar',
									// 	buttonName: 'Email Summary',
									// })
									onEmailBulkSummary(activity)
								},
							},
							{
								label: locale['Export Timeline'],
								isMenu: true,
								icon: <Icon as={BiDownload} w={5} h={5} color='brand.900' />,
								onClick: e => {
									// sectionDetailedTrack({
									// 	category: GA_CATEGORY_WORKSPACE_PANEL,
									// 	action: 'Open Export Timeline Modal',
									// })
									// dataLayerTagManager('button_click', {
									// 	viewName: 'Workflow Navbar',
									// 	buttonName: 'Export Timeline',
									// })
									onOpenExportTimeline()
								},
							},
							{
								label: locale['Export Forms'],
								isMenu: true,
								icon: <Icon as={BiDownload} w={5} h={5} color='brand.900' />,
								onClick: e => {
									// sectionDetailedTrack({
									// 	category: GA_CATEGORY_WORKSPACE_PANEL,
									// 	action: 'Open Export Forms Modal',
									// })
									// dataLayerTagManager('button_click', {
									// 	viewName: 'Workflow Navbar',
									// 	buttonName: 'Export Forms',
									// })
									setSelectedForms([])
									onOpenExportForms()
								},
							},
							{
								label: !!activity?.activity_priority_enabled
									? locale['Remove Priority']
									: locale['Set Priority'],
								isMenu: true,
								disabled: !(
									activity?.activity_type_flag_enable_priority &&
									activityEnable() &&
									timeColor(activity_datetime_end_deferred) !== 'red'
								),
								icon: <Icon as={FaPaperPlane} w={4} h={4} color='brand.900' />,
								onClick: e => {
									onSetPriority(!!activity?.activity_priority_enabled ? 0 : 1)
								},
							},
							{
								label: 'Email Communications',
								isMenu: true,
								disabled: asset_flag_super_admin
									? false
									: activity.activity_creator_asset_id !== asset_id,
								icon: <Icon as={BiMailSend} w={4} h={4} color='brand.900' />,
								onClick: e => {
									onOpenEmial()
								},
							},

							// {
							//   label: 'Send Email',
							//     onOpenSendEmail();
							//   },
							// },
						]}
						selected={{}}
					/>
				</HStack>
				<Collapse
					in={isOpen}
					animateOpacity
					className={`activity-body-${toSnakeCase(activity?.activity_title)} ${
						isOpen ? 'expand' : ''
					}`}
				>
					<Divider py={1} />
					<Box px={1} mt={2} shadow='md' bg={'white'}>
						<HStack alignItems='flex-start' justifyContent='flex-start'>
							<VStack flex={1} alignItems='flex-start'>
								<HStack
									alignItems='flex-start'
									justifyContent='flex-start'
									mb={1}
								>
									{activity_status_name && (
										<>
											<Tag
												className={`activity-status-tag-${toSnakeCase(
													activity_status_name
												)}`}
												variant='outline'
												borderRadius='full'
												colorScheme={localStorage.getItem('color')}
												size='md'
												fontSize='12px'
												mb={1}
											>
												<TagLabel maxW='full'>{activity_status_name}</TagLabel>
											</Tag>
											{/* <Box>
                        <Select
                          value={currentStatus}
                          options={previousStatus}
                          onChange={e => {
                            setCurrentStatus(e);
                          }}
                          styles={customPillStyles}
                          isSearchable={false}
                          isDisabled={disableRollbackStatus}
                        />
                      </Box> */}
											{/* {activityTypeDetails?.activity_type_arp_lead_setting_enabled ==
                        1 && (
                        <Box>
                          <IconButton
                            bg="white"
                            ml={2}
                            aria-label="Rollback Status"
                            size="md"
                            _focus={{
                              bg: 'secondary',
                            }}
                            _hover={{
                              bg: 'secondary',
                            }}
                            onClick={rollbackStatus}
                            icon={
                              disableRollbackStatus ? (
                                <Icon as={MdHistory} w={5} h={5} />
                              ) : (
                                <Icon as={MdDone} w={5} h={5} />
                              )
                            }
                          />
                        </Box>
                      )} */}
										</>
									)}

									{activity_rag_status_name && (
										<>
											<Text>{locale['RAG Status']}: </Text>
											<Tag
												className={`activity-status-tag-${toSnakeCase(
													activity_rag_status_name
												)}`}
												variant='outline'
												borderRadius='full'
												colorScheme={`${
													rag_status[activity_rag_status_name.toLowerCase()]
												}`}
												size='md'
												fontSize='12px'
												mb={1}
											>
												<TagLabel maxW='full'>
													{activity_rag_status_name}
												</TagLabel>
											</Tag>
										</>
									)}
								</HStack>
							</VStack>
						</HStack>
						<HStack spacing={1} alignItems='flex-start'>
							<VStack flex={1} alignItems='flex-start'>
								<HStack alignItems='flex-start' justifyContent='flex-start'>
									<Text
										fontSize='12px'
										mb={1}
										className={`activity-type-${toSnakeCase(
											activity_type_category_name
										)}`}
									>
										{activity_type_category_name} :{' '}
										<Text
											as='span'
											fontWeight='500'
											className={`activity-type-${toSnakeCase(
												activity_type_name
											)}`}
										>
											{activity_type_name}
										</Text>
									</Text>
									{!!completion && (
										<HStack
											spacing={2}
											flex={1}
											className={`activity-completion-container`}
										>
											<Slider
												className={`activity-completion-slider`}
												aria-label='slider-ex-2'
												isDisabled={true}
												// defaultValue={completion || 0}
												value={completion}
												w='150px'
											>
												<SliderTrack
													height={2}
													borderRadius='full'
													className={`activity-completion-track`}
												>
													<SliderFilledTrack
														bg='orange.600'
														className={`activity-completion-fill`}
													/>
												</SliderTrack>
											</Slider>
											<Text
												className={`activity-completion-text-percentage`}
												color={localStorage.getItem('color')}
											>
												{`${completion}%` || 0}
											</Text>
										</HStack>
									)}
								</HStack>
							</VStack>
							<VStack
								className={`activity-creation-details`}
								w='300px'
								justifyContent='flex-start'
								alignItems='flex-start'
							>
								<Text fontSize='12px' className={`activity-creator-text`}>
									{locale['Creator']} :{' '}
									<Text
										as='span'
										fontWeight='500'
										className={`activity-creator-text-${toSnakeCase(
											operating_asset_first_name
										)}`}
									>
										{operating_asset_first_name}
									</Text>
								</Text>
								{activity_lead_operating_asset_first_name && (
									<Text fontSize='12px' className={`activity-lead-text`}>
										{locale['Lead']} :{' '}
										<Select
											value={currentLead}
											options={possibleLead}
											onChange={e => {
												setCurrentLead(e)
											}}
											styles={customStyles}
											isSearchable={true}
											isDisabled={disableEditLead}
										/>
										{activityTypeDetails?.activity_type_arp_lead_setting_enabled ===
											1 &&
											(activity.asset_flag_is_owner ||
												activity.activity_creator_asset_id ===
													authData.asset_id) && (
												<IconButton
													bg='white'
													ml={2}
													aria-label='Edit Current Lead'
													size='md'
													_focus={{
														bg: 'secondary',
													}}
													_hover={{
														bg: 'secondary',
													}}
													icon={
														disableEditLead ? (
															<Icon as={MdEdit} w={5} h={5} />
														) : (
															<Icon as={MdDone} w={5} h={5} />
														)
													}
													onClick={() => {
														// sectionDetailedTrack({
														// 	category: GA_CATEGORY_WORKSPACE_PANEL,
														// 	action: 'Change Lead By Manager',
														// })
														changeLeadByManager()
													}}
												/>
											)}
									</Text>
								)}
							</VStack>
							<VStack
								alignItems='flex-start'
								className={`activity-time-log-container`}
							>
								<Text
									fontSize='12px'
									display='flex'
									alignItems='center'
									className={`activity-time-text-${activity_datetime_start_expected}`}
								>
									<Icon
										as={MdDateRange}
										w={4}
										h={4}
										className={`activity-date-time-icon`}
									/>
									&nbsp; {locale['Start Date']}: &nbsp;
									{/* <Text display='flex' alignItems='center' > */}
									{!!activity_datetime_start_expected
										? format(
												new Date(
													activity_datetime_start_expected.split(' ').join('T')
												),
												'dd MMM yyyy'
										  )
										: !!activity_datetime_start_expected
										? format(
												new Date(
													activity_datetime_start_expected.split(' ').join('T')
												),
												'dd MMM yyy'
										  )
										: null}
									{/* </Text> */}
								</Text>
								{!!activity_datetime_end_deferred && (
									<Text
										fontSize='12px'
										display='flex'
										alignItems='center'
										color={timeColor(activity_datetime_end_deferred)}
										className={`activity-end-time-text-${activity_datetime_end_deferred}`}
									>
										<Icon
											as={MdTimelapse}
											w={4}
											h={4}
											className={`activity-time-laps-icon`}
										/>
										&nbsp; {locale['Due Date']} : &nbsp;
										{/* <Text display='flex' alignItems='center'  color={timeColor(activity_datetime_end_deferred)}> */}
										{activity_datetime_end_deferred.includes('date') ||
										activity_datetime_end_deferred.includes('0000-00-00')
											? 'NA'
											: format(
													new Date(
														activity_datetime_end_deferred.split(' ').join('T')
													),
													'dd MMM yyyy'
											  )}
										{/* </Text> */}
									</Text>
								)}
								{!!activity_datetime_end_status &&
									organization_ai_bot_enabled === 1 && (
										<Text
											fontSize='12px'
											display='flex'
											alignItems='center'
											color={timeColor(activity_datetime_end_status)}
											className={`activity-time-text-${activity_datetime_end_deferred}`}
										>
											<Icon
												as={MdAccessTime}
												w={4}
												h={4}
												className={`activity-date-time-icon`}
											/>
											&nbsp; {locale['Status Due Date']} : &nbsp;
											{/* <Text display='flex' alignItems='center' color={timeColor(activity_datetime_end_status)} > */}
											{!!activity_datetime_end_status
												? format(
														new Date(
															activity_datetime_end_status.split(' ').join('T')
														),
														'dd MMM yyyy'
												  )
												: !!activity_datetime_end_status
												? format(
														new Date(
															activity_datetime_end_status.split(' ').join('T')
														),
														'dd MMM yyy'
												  )
												: null}
											{/* </Text> */}
										</Text>
									)}
							</VStack>
							{activity_type_category_id === 66 && (
								<div style={{ padding: '3px 3px' }}>
									<QRCodeSVG
										renderAs='canvas'
										style={{
											height: '75px',
											width: '75px',
										}}
										level='Q'
										value={`${activity_cuid_1}`}
									/>
								</div>
							)}
						</HStack>
						<HStack
							className={`activity-cuid-container`}
							justifyContent='space-between'
							mt={2}
							alignItems='center'
							spacing={4}
						>
							{activity_cuid_1 && (
								<Text mb={1} fontSize='12px' className={`activity-CUID-1-text`}>
									{locale['CUID 1']} :{' '}
									<Text
										as='span'
										fontWeight='500'
										className={`activity-CUID-1-text-${activity_cuid_1}`}
									>
										{activity_cuid_1}
									</Text>{' '}
									<Icon
										className={`activity-CUID-1-copy-icon`}
										as={FiCopy}
										cursor='pointer'
										onClick={e => {
											// sectionDetailedTrack({
											// 	category: GA_CATEGORY_WORKSPACE_PANEL,
											// 	action: 'Activity CUID 1 copied',
											// })
											navigator.clipboard.writeText(activity_cuid_1)
											e.stopPropagation()
											toast({
												title: 'Copied!',
												status: 'success',
											})
										}}
										w={3}
										h={3}
									/>{' '}
								</Text>
							)}
							{activity_cuid_2 && (
								<Text mb={1} fontSize='12px' className={`activity-CUID-2-text`}>
									{locale['CUID 2']} :{' '}
									<Text
										as='span'
										fontWeight='500'
										className={`activity-CUID-2-text-${activity_cuid_2}`}
									>
										{activity_cuid_2}
									</Text>{' '}
									<Icon
										className={`activity-CUID-2-copy-icon`}
										as={FiCopy}
										cursor='pointer'
										onClick={e => {
											// sectionDetailedTrack({
											// 	category: GA_CATEGORY_WORKSPACE_PANEL,
											// 	action: 'Activity CUID 2 copied',
											// })
											navigator.clipboard.writeText(activity_cuid_2)
											e.stopPropagation()
											toast({
												title: 'Copied!',
												status: 'success',
											})
										}}
										w={3}
										h={3}
									/>{' '}
								</Text>
							)}
							{activity_cuid_3 && (
								<Text mb={1} className={`activity-CUID-3-text`} fontSize='12px'>
									{locale['CUID 3']} :{' '}
									<Text
										as='span'
										fontWeight='500'
										className={`activity-CUID-3-text-${activity_cuid_3}`}
									>
										{activity_cuid_3}
									</Text>{' '}
									<Icon
										as={FiCopy}
										cursor='pointer'
										onClick={e => {
											// sectionDetailedTrack({
											// 	category: GA_CATEGORY_WORKSPACE_PANEL,
											// 	action: 'Activity CUID 3 copied',
											// })
											navigator.clipboard.writeText(activity_cuid_3)
											e.stopPropagation()
											toast({
												title: 'Copied!',
												status: 'success',
											})
										}}
										w={3}
										h={3}
									/>{' '}
								</Text>
							)}
							{/*  {participants && !!participants.length && (
                <AvatarGroup
                  justifySelf="flex-end"
                  size="sm"
                  max={3}
                  spacing="-5px"
                  onClick={onOpen}
                  cursor="pointer"
                >
                  {(participants || []).map(p => {
                    return (
                      <Avatar
                        key={p.asset_id}
                        name={p.operating_asset_first_name}
                      />
                    );
                  })}
                  <Avatar name="+" key="new" bg="secondary" color={localStorage.getItem('color')} />
                </AvatarGroup>
              )} */}
						</HStack>
					</Box>
				</Collapse>
			</Card>
			{activityParticipantId !== 152 ? (
				<>
					{WORKSPACE_MODE.LOADER_VIEW !== workspaceMode && (
						<HStack justifyContent='space-between' w='full' px={2}>
							<Button
								id={`workspace-panel-file-timeline-button`}
								w='32%'
								overflowX='clip'
								// minW="250px"
								fontWeight='400'
								bg='white'
								colorScheme={localStorage.getItem('color')}
								variant='outline'
								size='sm'
								leftIcon={<Icon as={MdPeople} w={6} h={6} mr={5} />}
								transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
								transform='scale(0.95)'
								isActive={workspaceMode === WORKSPACE_MODE.TEAM_COLLABORATION}
								onClick={() => {
									// eventLabelActionTrack({
									// 	categoryId: 0,
									// 	action: 'File Timeline',
									// 	label: 'Switch File Timeline View',
									// })
									setworkspaceMode(WORKSPACE_MODE.TEAM_COLLABORATION)
								}}
								_focus={{ border: 'none' }}
								_hover={{ bg: 'secondary' }}
								_active={{
									bg: localStorage.getItem('color'),
									transform: 'scale(1)',
									color: '#fff',
								}}
							>
								{locale['File Timeline']}
							</Button>
							<Button
								id={`workspace-panel-data-management-button`}
								w='32%'
								overflowX='clip'
								// minW="250px"
								fontWeight='400'
								bg='white'
								colorScheme={localStorage.getItem('color')}
								isActive={workspaceMode === WORKSPACE_MODE.DATA_MANAGEMENT}
								onClick={() => {
									// eventLabelActionTrack({
									// 	categoryId: 0,
									// 	action: 'Data Management',
									// 	label: 'Switch Data Management View',
									// })
									loadDetails(activity)
									setworkspaceMode(WORKSPACE_MODE.DATA_MANAGEMENT)
								}}
								variant='outline'
								size='sm'
								leftIcon={<Icon as={TiDocumentText} w={6} h={6} mr={5} />}
								transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
								transform='scale(0.95)'
								_focus={{ border: 'none' }}
								_hover={{ bg: 'secondary' }}
								_active={{
									bg: localStorage.getItem('color'),
									transform: 'scale(1)',
									color: '#fff',
								}}
							>
								{locale['Data Management']}
							</Button>
							<Button
								id={`workspace-panel-status-lifecycle-button`}
								w='32%'
								overflowX='clip'
								// minW="250px"
								fontWeight='400'
								bg='white'
								colorScheme={localStorage.getItem('color')}
								isActive={workspaceMode === WORKSPACE_MODE.STATUS_LIFECYCLE}
								onClick={() => {
									// eventLabelActionTrack({
									// 	categoryId: 0,
									// 	action: 'Status Life Cycle',
									// 	label: 'Switch Status Life Cycle View',
									// })
									loadDetails(activity)
									setworkspaceMode(WORKSPACE_MODE.STATUS_LIFECYCLE)
								}}
								variant='outline'
								size='sm'
								leftIcon={<Icon as={AiOutlineLineChart} w={6} h={6} mr={5} />}
								transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
								transform='scale(0.95)'
								_focus={{ border: 'none' }}
								_hover={{ bg: 'secondary' }}
								_active={{
									bg: localStorage.getItem('color'),
									transform: 'scale(1)',
									color: '#fff',
								}}
							>
								{locale['Status Life Cycle']}
							</Button>
							{!disableReferenceFiles || isSuperAdmin() ? (
								<Button
									id={`workspace-panel-reference-files`}
									w='32%'
									overflowX='clip'
									// minW="250px"
									fontWeight='400'
									bg='white'
									colorScheme={localStorage.getItem('color')}
									isActive={workspaceMode === WORKSPACE_MODE.REFERENCE_FILES}
									onClick={() => {
										// eventLabelActionTrack({
										// 	categoryId: 0,
										// 	action: 'Reference Files',
										// 	label: 'Switch Reference Files View',
										// })
										setworkspaceMode(WORKSPACE_MODE.REFERENCE_FILES)
									}}
									variant='outline'
									size='sm'
									leftIcon={<Icon as={VscReferences} w={6} h={6} mr={5} />}
									transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
									transform='scale(0.95)'
									_focus={{ border: 'none' }}
									_hover={{ bg: 'secondary' }}
									_active={{
										bg: localStorage.getItem('color'),
										transform: 'scale(1)',
										color: '#fff',
									}}
								>
									{locale['Reference Files']}
								</Button>
							) : null}
						</HStack>
					)}

					<Box
						w='full'
						background='white'
						borderRadius='md'
						minH={isOpen ? 'calc(100vh - 280px)' : 'calc(100vh - 180px)'}
					>
						{WORKSPACE_MODE.LOADER_VIEW === workspaceMode && (
							<Box h='calc(100vh - 180px)' w='full'>
								<Center p='1' h={'100%'}>
									<GridLoader size={30} margin={5} color='#64bc78' />
								</Center>
							</Box>
						)}
						{WORKSPACE_MODE.TEAM_COLLABORATION === workspaceMode && (
							<TeamCollaboration
								activity={activity}
								isOverviewOpen={isOpen}
								participants={participants}
								reloadParticipant={() =>
									loadParticipants({
										activity_id,
									})
								}
								onOpenParticipantView={onOpen}
								onOpenSendEmail={onOpenSendEmail}
								onFormSelection={f => {
									const { activity_id, activity_type_id } = activity
									// TODO: only super adming, lead , owner can perform form submission.
									// FIXME: @jaya please chekc .
									setopenFormView({
										...f,
										disableEdit: true,
										activity_id,
										activity_type_id,
									})
									onOpenFormEditView()
									setisBulkEdit(false)
								}}
								loadSuperAdmins={loadSuperAdmins}
							/>
						)}
						{WORKSPACE_MODE.DATA_MANAGEMENT === workspaceMode && (
							<DataManagement
								participants={participants}
								isOverviewOpen={isOpen}
								isFormEnable={activityEnable()}
								activity={activity}
								onFormSelection={(
									f,
									isEdit = false,
									owner_access = true,
									form_flag_enable_data_secrecy = 0
								) => {
									console.log({
										f,
										isEdit,
										owner_access,
										form_flag_enable_data_secrecy,
									})
									const { activity_id, activity_type_id } = activity
									setopenFormView({
										...f,
										disableEdit: false,
										activity_id,
										activity_type_id,
									})
									setisBulkEdit(false)
									setRefillView(false)
									if (
										!!isEdit &&
										activityEnable(owner_access, form_flag_enable_data_secrecy)
									) {
										onOpenFormEditView()
									} else if (
										!!isEdit &&
										!activityEnable(owner_access, form_flag_enable_data_secrecy)
									) {
										onOpenNoAccess()
									} else {
										if (activityEnable(owner_access)) {
											setworkspaceMode(WORKSPACE_MODE.FORM_SUBMISSION_VIEW)
										} else {
											onOpenNoAccess()
										}
									}
								}}
								onDelegateFormClose={() => {
									setworkspaceMode(WORKSPACE_MODE.DATA_MANAGEMENT)
									loadDetails(activity)
									setTimeout(() => {
										dispatch({
											type: sharedConst.REFRESH_LISTING,
										})
									}, 1000)
								}}
							/>
						)}
						{WORKSPACE_MODE.STATUS_LIFECYCLE === workspaceMode && (
							<StatusLifeCycle
								participants={participants}
								isOverviewOpen={isOpen}
								isFormEnable={activityEnable()}
								activity={activity}
							/>
						)}
						{WORKSPACE_MODE.REFERENCE_FILES === workspaceMode && (
							<ReferenceFileContainer
								activity={activity}
								isOverviewOpen={isOpen}
							/>
						)}
						{WORKSPACE_MODE.FORM_SUBMISSION_VIEW === workspaceMode && (
							<Box bg='white' borderRadius='md' p={2}>
								<SmartFormContextProvider>
									<SmartFormPanel
										onClose={() => {
											setworkspaceMode(WORKSPACE_MODE.TEAM_COLLABORATION)
											loadDetails(activity)
											setTimeout(() => {
												dispatch({
													type: sharedConst.REFRESH_LISTING,
												})
											}, 1000)
											doRefresh(0)
										}}
										formData={openFormView}
										activity={activity}
										handleError={() => {
											console.log('error occurred!')
										}}
										refillView={refillView}
										isBulkEdit={isBulkEdit}
									/>
								</SmartFormContextProvider>
							</Box>
						)}
					</Box>
				</>
			) : null}
			{isOpenRenameActivity && (
				<RenameModal
					onOpen={isOpenRenameActivity}
					type={''}
					validation={false}
					name={activity?.activity_title}
					onSubmit={onRenameActivity}
					onClose={onCloseRename}
				/>
			)}
			<ActivityClone
				isDashboard={isDashboard}
				activity={activity}
				isOpen={isCloneActivity}
				onClose={closeCloneActivity}
			/>
			<Modal
				closeOnOverlayClick={false}
				isOpen={isDeclineOpen}
				onClose={onCloseDecline}
			>
				<ModalOverlay />
				<ModalContent
					borderRadius='md'
					bg='white'
					minW='40vw'
					m='auto'
					maxH='600px'
				>
					<ModalHeader display={'flex'} justifyContent={'center'}>
						<Text color={'brand.800'}>Decline History</Text>
					</ModalHeader>
					<ModalCloseButton zIndex='100' />
					<ModalBody p={0} w='100%' position='relative'>
						<VStack px={10} py={5} alignItems={'flex-start'}>
							<List spacing={3} w={'full'}>
								{declineData.map((list, index) => {
									return (
										<ListItem
											key={index}
											borderBottom={'1px'}
											borderBottomColor={'blackAlpha.300'}
										>
											<Text fontSize={'16px'} color={'black'}>
												{list?.operating_asset_first_name}
											</Text>
											<Text color={'blackAlpha.600'}>
												{list?.timeline_transaction_datetime &&
													getDeclineTimeDate(
														list?.timeline_transaction_datetime
													)}
											</Text>
										</ListItem>
									)
								})}
							</List>
						</VStack>
					</ModalBody>
					<ModalFooter>
						<Button
							borderRadius='sm'
							size='sm'
							colorScheme={localStorage.getItem('color')}
							bg={localStorage.getItem('color')}
							variant='solid'
							onClick={() => {
								onOpen()
								onCloseDecline()
								setInviteDelegate(true)
							}}
							mx={2}
						>
							{locale['Delegate']}
						</Button>
						<Button
							borderRadius='sm'
							size='sm'
							colorScheme={localStorage.getItem('color')}
							variant='outline'
							onClick={onCloseDecline}
						>
							{locale['Close']}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Drawer
				className={`form-edit-view-drawer ${
					isOpenExportData ? 'expanded' : ''
				}`}
				isOpen={isOpenExportData}
				placement='right'
				onClose={onCloseExportData}
				size='xl'
			>
				<DrawerOverlay className={`form-edit-drawer-overlay`} />
				<DrawerContent className={`form-edit-drawer-content`}>
					<DrawerCloseButton className={`form-edit-drawer-close-button`} />
					<DrawerHeader
						boxShadow='sm'
						bg='secondary'
						className={`form-edit-drawer-header`}
					>
						<VStack alignItems='flex-start' flex={1}>
							<Text
								className={`form-edit-drawer-text`}
								flex={1}
								fontSize='md'
								fontWeight='500'
								textAlign='center'
								textTransform='capitalize'
							>
								{'Export Data'}
							</Text>
							<Divider />
							<HStack w={'full'} justifyContent={'space-between'}>
								<ReactToPrint content={() => pdf_ref?.current}>
									<PrintContextConsumer>
										{({ handlePrint }) => (
											<Button
												borderRadius='sm'
												size='sm'
												colorScheme={localStorage.getItem('color')}
												bg={localStorage.getItem('color')}
												variant='solid'
												onClick={e => {
													handlePrint(e)
												}}
												ml={3}
											>
												{'Print'}
											</Button>
										)}
									</PrintContextConsumer>
								</ReactToPrint>
								{/* <Button
									borderRadius='sm'
									size='sm'
									colorScheme={localStorage.getItem('color')}
									variant='solid'
									onClick={e => {
										handleDownload(e)
									}}
									ml={3}
								>
									{'Download'}
								</Button> */}
								<Text color={'brand.800'} fontSize='12px' fontWeight={'500'}>
									Downloading of the file will take few seconds.
								</Text>
							</HStack>
						</VStack>
					</DrawerHeader>
					<DrawerBody className={`form-edit-drawer-body`} height='100%'>
						{React.createElement(ExportData, {
							exportParams: buildTimelineExportPayload(),
							ref: pdf_ref,
						})}
					</DrawerBody>
				</DrawerContent>
			</Drawer>
			<AlertDialog
				isOpen={isOpenNoAccess}
				leastDestructiveRef={cancelRef}
				onClose={onCloseNoAccess}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							{'Form Access Denied'}
						</AlertDialogHeader>

						<AlertDialogBody>
							{'Form access denied. Please contact admin'}
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button
								ref={cancelRef}
								colorScheme={localStorage.getItem('color')}
								bg={localStorage.getItem('color')}
								onClick={() => {
									onCloseNoAccess()
								}}
							>
								{locale['Ok']}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</VStack>
	)
}

export default WorkspacePanel
